<template>
  <v-app>
    <div class="modal-mask">
      <div class="book-wrap" id="book-wrap">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div>{{ property?.name }}</div>
            </div>
            <div class="modal-body">
              <v-main class="main">
                <v-col v-if="route.meta.hideMenus == true"></v-col>
                <v-col v-else class="text-right">
                  <SitebookToggleNavigation></SitebookToggleNavigation>
                  <ClientOnly>
                    <NuxtLink :to="'/sitebook/cart'">
                      <v-btn class="text-none align-right" size="large" color="white" icon="mdi-cart" stacked>
                        <v-badge color="teal" :content="getCartLength()" size="x-large">
                          <v-icon size="x-large" icon="mdi-cart"></v-icon>
                        </v-badge>
                      </v-btn>
                    </NuxtLink>
                  </ClientOnly>
                </v-col>
                <slot></slot>
              </v-main>
            </div>
            <div class="modal-footer"> Powered by
              <Logo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script lang="ts" setup>
import Logo from '@/components/layout/Logo.vue'
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();
let cartStore = useCartStore();
let cart = storeToRefs(useCartStore()).cart;
let route = useRoute();
let property = await usePropertyStore().currentProperty;
onMounted(async () => {
  cartStore.getCart();
});
function scrollToTop() {
  const myDiv = document.getElementById("book-wrap");
  if (myDiv) {
    myDiv.scrollTop = 0;
  }
}

function getCartLength() { return cart.value.length; }

function closeParentiFrame(stay: boolean) {
  if (!stay) {
    window.parent.postMessage('{ "type": "treqit.close" }', "*");
    window.postMessage('{ "type": "treqit.close" }', "*");
  }
}

</script>

<style scoped lang="scss">
.book-logo {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  width: 150px;
}

@media only screen and (max-width: 680px) {

  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    position: relative;
    width: 100%;
    padding-right: 3px;
    padding-left: 3px;
  }

  .container,
  .container-fluid,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    padding-right: 2px;
    padding-left: 2px;
    margin-right: auto;
    margin-left: auto;
  }
}

.modal-footer {
  display: block;
}

@media only screen and (max-width: 680px) {
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px;
  }
}

@media only screen and (min-width: 680px) {
  .modal-dialog {
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 15px 30px 15px;
  }

  .container {
    // override
    padding-right: 5px;
    padding-left: 5px;
  }
}

.book-wrap {
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 1072;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 7px;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  box-shadow: 0 5px 15px rgb(0 0 0 / 17%);
  border: 1px solid #afb8c0;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 1100px !important;
  }
}


.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px)
}

@media(prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  /* box-shadow:0 .25rem .5rem rgba(0,0,0,.5); */
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .1
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media(min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem)
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content
  }

  .modal-content {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5);
  }

  .modal-sm {
    max-width: 300px
  }
}

@media(min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px
  }
}

@media(min-width: 1200px) {
  .modal-xl {
    max-width: 1140px
  }
}
</style>