<template>
    <div v-if="show()" class="grid-toggle-toolbar">
        <div class="grid-toggle-toolbar-content">
            <v-btn-group v-if="property">
                <!-- <NuxtLink v-for="unitGroup in unitGroups" :to="`/sitebook/${$route.params.propertyguid}/grid`">
                    <v-chip class="mr-6" size="x-large" variant="outlined">
                        {{ unitGroup.name }}
                    </v-chip>
                </NuxtLink> -->
                <NuxtLink :to="`/sitebook/${$route.params.propertyguid}/grid`">
                    <v-btn size="sm" aria-pressed="true" variant="elevated" :class="[{ active: selected == 'grid' }]">
                        <v-icon icon="grid3x3-gap-fill"></v-icon> Calendar
                    </v-btn>
                </NuxtLink>
                <NuxtLink :to="`/sitebook/${$route.params.propertyguid}/company`">
                    <v-btn size="sm" aria-pressed="true" variant="elevated" :class="[{ active: selected == 'grid' }]">
                        <v-icon icon="grid3x3-gap-fill"></v-icon> All Items
                    </v-btn>
                </NuxtLink>
            </v-btn-group>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { getCategories } from '~/services/CategoryService';
import { UnitGroup, getAllUnitGroups } from '~/services/UnitGroupService';

const props = defineProps({
    selectedToggle: {
        type: String,
        required: false, default: "grid"
    }, //can be grid, types, or dates
});
let selected = ref("grid");
let route = useRoute();
let property = usePropertyStore().currentProperty;

//let categories = await getCategories(); // to do: for this property

//const config = useRuntimeConfig();

//let unitGroups = await getAllUnitGroups(property.propertyGuid as string);
// let unitGroups = await useFetch(
//     `/unitgroup/getunitgroups/${property.propertyGuid}`,
//     {
//         baseURL: config.public.appApi,
//         method: 'GET',
//         body: null,
//         credentials: 'same-origin',
//         headers: headers(),
//     },
// ).then((response) => {
//     if (response.status.value == 'success') {
//         let result = response.data.value as unknown as Array<UnitGroup>;
//         return result;
//     }
// });


//let unitGroups = (property) ?  await getUnitGroups(property.propertyGuid) : [];

function show() {
    if (
        route.name?.toString().toLowerCase() == "cart" ||
        route.name?.toString().toLowerCase() == "confirmation"
    ) {
        return false;
    } else {
        return true;
    }
}

</script>

<style scoped lang="scss">
.grid-toggle-toolbar {
    padding-bottom: 5px;
    display: flex;
}

.grid-toggle-toolbar-content {
    flex-grow: 1;
    text-align: center;
}
</style>